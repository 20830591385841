import { ActionContext } from 'vuex';

import $http from '@/plugins/axios';

import { IRootState } from '@/interfaces/RootState';
import { IFiscalCalendarResponse, IFiscalCalendarState, IFiscalCalendarRow } from '@/interfaces/FiscalCalendar';

export default {
  namespaced: true,

  state: {
    fiscalCalendar: {
      rows: [],
    },
  },

  getters: {
    getFiscalCalendar: (
      state: IFiscalCalendarState,
    ): IFiscalCalendarRow[] => {
      if (!state.fiscalCalendar || !state.fiscalCalendar.rows) {
        return [];
      }
      return state.fiscalCalendar.rows;
    },
  },

  mutations: {
    SET_FISCAL_CALENDAR(state: IFiscalCalendarState, payload: IFiscalCalendarResponse): void {
      state.fiscalCalendar = payload;
    },

    RESET_FISCAL_CALENDAR(state: IFiscalCalendarState): void {
      state.fiscalCalendar = {
        rows: [],
      };
    },
  },

  actions: {
    /**
     *
     *
     * @param {ActionContext<IDatasetState, IRootState>} { commit }
     * @param {IDatasetRequest} payload
     * @return {*}  {Promise<void>}
     */
    async fetchFiscalCalendar(
      { commit }: ActionContext<IFiscalCalendarState, IRootState>,
      customer: string,
    ): Promise<void> {
      try {
        const response = await $http.Api({
          method: 'GET',
          url: `/viewdata/fiscalcalendar/${customer}`,
        });

        commit('RESET_FISCAL_CALENDAR', response.data);
      } catch (error) {
        throw error.response;
      }
    },
  },
};
